import React from 'react';
import { Paper, Box, Stack, Typography, Skeleton } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material'
import { LabelStack } from '../../Components/LabelStack';

export const VerificationList = ({ orderSummaries, handleSelectOrder }) => {
  return (
    <Stack spacing='0.75rem'>
      {orderSummaries.map(({ orderId, title, jurisdictions = [], labels }) =>
        <Box key={`order-${orderId}`} onClick={() => handleSelectOrder(orderId)} sx={{ '&:hover': { boxShadow: 3 }}}>
          <Paper sx={{ width: '100%', padding: '0.75rem' }}>
            <Stack direction='row' justifyContent='space-between' height='3rem' alignItems='center' width='100%'>
              <Stack direction='row' spacing='1rem'>
                <Skeleton variant='circular' width='2.5rem' height='2.5rem' animation={false} />
                <Stack direction='column' spacing='0.25rem'>
                  <Stack direction='row' sx={{ alignItems: 'end' }} spacing='0.25rem'>
                    <Typography variant="h5"  lineHeight='1.25rem' color='emphasis.main'>{title}</Typography>
                    <Typography variant='h6' lineHeight='1.25rem' color='emphasis.light'>{['', ...jurisdictions].join(' / ')}</Typography>
                  </Stack>
                  <LabelStack labels={labels} />
                </Stack>

              </Stack>
              <KeyboardArrowRight color='primary' />
            </Stack>
          </Paper>
        </Box>,
      )}
    </Stack>
  )
}