import { useEffect } from "react";
import { Authenticator, useAuthenticator, View, Image, useTheme } from '@aws-amplify/ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../Images/meshLogo.svg'
import './Login.css'

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <img src={logo} />
      </View>
    );
  },
}

const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <View className="auth-wrapper">
      <Authenticator hideSignUp={true} components={components}></Authenticator>
    </View>
  );
}
export default Login
