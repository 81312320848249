import { Box, Button, Typography, CircularProgress } from '@mui/material'
import React, { useState, useContext } from 'react'
import { ReactComponent as Inactive } from '../../Assets/red_inactive_user.svg'
import { ReactComponent as Expired } from '../../Assets/red_expired_user.svg'
import { ReactComponent as Green } from '../../Assets/green_user.svg'
import { ReactComponent as Yellow } from '../../Assets/yellow_requested_user.svg'
import { BuildDropdown } from '../../Components/Inputs'
import UserContext from '../../services/userService'

const UserCard = ({ batch, roleValues, statusValues, allowEditing }) => {
  const service = useContext(UserContext)
  const [isEditing, setIsEditing] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const[filters, setFilters] = useState({
    status: batch.status,
    role: batch.role,
  })

  const filterOnChange = async (key, e) => {
    console.log('bleh', key, e)
    if(key.includes('status'))
      setFilters((oldState) => ({
          ...oldState,
          status: e,
      }))
    if(key.includes('role'))
      setFilters((oldState) => ({
          ...oldState,
          role: e,
      }))
  }

  const updateUser = async () => {
    setIsUpdating(true)
    const response = await service.updateCurrentUser(batch.userId, filters)
    setIsUpdating(false)
  }

  const reinviteUser = async () => {
    setIsUpdating(true)
    const response = await service.reinviteUser(batch.userId)
    setIsUpdating(false)
  }

  const handleClick = async () => {
    if(isEditing)
      await updateUser()
    setIsEditing(!isEditing)
  }

  return (
    <Box sx={{
      width: '100%',
      margin: '0 0 1rem 0',
      padding: '0.5rem',
      textAlign: 'start',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderBottom: '1px solid #9e9e9f20',
      backgroundColor: 'background.paper',
      '&:hover': { boxShadow: 3 },
    }}>
      <Box sx={{
        marginRight: '0.25rem',
        display: 'flex',
        flex: '3',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}>
        {batch.status === 'INACTIVE'
          ? <Inactive width={'4rem'}/>
          : batch.status === 'INVITED'
            ? <Yellow width={'4rem'}/>
            : batch.status === 'EXPIRED'
              ? <Expired width={'4rem'}/>
              : <Green width={'4rem'}/>
          }
        <Typography noWrap sx={{
          width: '100%',
          textOverflow: 'ellipsis',
          marginLeft: '1.5rem',
          overflow: 'hidden',
          fontWeight: '600',
        }}>
          {batch.fullName.includes('undefined') ? 'Invited User' : batch.fullName}
        </Typography>
      </Box>

      <Typography component={'span'} noWrap sx={{
        marginRight: '0.25rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        flex: '2',
        fontWeight: '400',
        color: '#9e9e9f',
      }}>
        {isEditing 
        ? <BuildDropdown currentValue={filters.role} values={roleValues} setter={(e) => {filterOnChange('role', e)}} disableUnderline={true} isSmall={true}/>
        : batch.role === 'ADMIN' ? 'Super User' : 'User'
        }
      </Typography>

      <Typography noWrap sx={{
        marginRight: '0.25rem',
        textOverflow: 'ellipsis',
        flex: '2',
        overflow: 'hidden',
        fontWeight: '400',
        color: '#9e9e9f',
      }}>
        {batch.emailAddress}
      </Typography>

      <Typography component={'span'} noWrap sx={{
        marginRight: '0.25rem',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize',
        flex: '2',
        overflow: 'hidden',
        fontWeight: '400',
        color: '#9e9e9f',
      }}>
        {isEditing 
        ? <BuildDropdown currentValue={filters.status} values={statusValues} setter={(e) => {filterOnChange('status', e)}} disableUnderline={true} isSmall={true}/>
        : batch.status === 'ACTIVE' 
          ? 'Active' 
          : batch.status === 'INACTIVE'
            ? 'Inactive'
            : batch.status === 'INVITED'
              ? 'Invited'
              : 'Expired'
        }
      </Typography>

      <Box sx={{
        marginRight: '0.25rem',
        flex: '1',
      }}>
      {allowEditing && <Button color='primary' variant={isEditing ? 'contained' : 'outlined'} sx={{
        padding: '0 1.5rem',
        textTransform: 'none',
      }} onClick={handleClick} disabled={isUpdating}>
        {isEditing 
        ? isUpdating ? <CircularProgress sx={{ color: '#0071f4'}} padding={5} size={10} color={'inherit'}/>  : 'Save'
        : 'Edit'
        }
      </Button>}

      {batch.status ==='INVITED' && <Button variant='outlined' sx={{
        padding: '0 1.5rem',
        color: '#0071f4',
        backgroundColor: '#fff',
        border: '1px solid #0071f4',
        textTransform: 'none',
        borderRadius: '0.5rem',
        ':hover': {
          color: '#fff',
          backgroundColor: '#0071f4',
        },
      }} onClick={reinviteUser} disabled={isUpdating}>
        {isUpdating ? (
        <Box sx={{ paddingTop: '0.45rem' }}>
          <CircularProgress size={16} color={'primary'}/>
          </Box>) : 'Reinvite'}
      </Button>}
      </Box>
    </Box>
  )
}


export default UserCard
