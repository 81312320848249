import React from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'
import { Error } from '@mui/icons-material'
import { ReactComponent as Green } from '../../Assets/green_csv.svg'
import { ReactComponent as GreenUser } from '../../Assets/green_user.svg'

import ErrorDialog from './ErrorDialog.jsx'

const CsvDialog = ({ open, handleClose, type, message }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        borderRadius: '0.5rem',
      }}>
        {type === 'uploading' ? <Green width={'8rem'}/> : type === 'user' ? <GreenUser width={'8rem'}/> : <Error sx={{ color: 'red', fontSize: '8rem' }}/>}
        <Typography sx={{
          fontWeight: '400',
          textAlign: 'center',
          fontSize: '1.1rem',
          color: '#424242',
          margin: '1rem 0 2rem 0',
        }}>
          {type === 'uploading' ? 'Your file has been uploaded successfully.' : type === 'user' ? 'Your invitation has been sent successfully.' : message}
        </Typography>
        <Button variant='outlined' sx={{
          padding: '0.5rem 5rem',
          color: '#0071f4',
          backgroundColor: '#fff',
          border: '1px solid #0071f4',
          textTransform: 'none',
          borderRadius: '0.5rem',
          fontSize: '1.25rem',
        }} onClick={handleClose}>
          OK
        </Button>
      </Box>
    </Modal>
  )
}

export default CsvDialog
export { ErrorDialog }