import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Box, Grid, TextField, InputAdornment, Chip, Stack, FormControl, InputLabel, Select, MenuItem, Drawer } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useParams } from 'react-router-dom'
import { VerificationTabs } from './VerificationTabs';
import { VerificationSidePanel } from './VerificationSidePanel';
import { WorkflowVerificationContext } from '../../services/workflowVerificationService';
import { LoadMore } from '../../Components/LoadMore';

const ORDER_TABS = [
  // Suppress the non-functional tabs for now
  // {
  //   tabLabel: 'Failed',
  //   tabValue: 'failed',
  //   filterFunction: ({ orderStatus }) => orderStatus === 'failed',
  // },
  // {
  //   tabLabel: 'In Progress',
  //   tabValue: 'inProgress',
  //   filterFunction: ({ orderStatus }) => orderStatus === 'inProgress',
  // },
  {
    tabLabel: 'All Businesses',
    tabValue: 'allProfessionals',
    filterFunction: () => true,
  },
]


export const VerificationContainer = () => {
  const { queryWorkflowVerifications } = useContext(WorkflowVerificationContext)
  const { orderId } = useParams()
  const [searchText, setSearchText] = useState('')
  const [searchTerms, setSearchTerms] = useState([])
  const [orderBy, setOrderBy] = useState('DATE_PLACED_DESC')

  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [orderSummaries, setOrderSummaries] = useState([])
  const [nextOffset, setNextOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const [selectedOrder, setSelectedOrder] = useState(orderId)

  const handleDelete = (input) => {
    setSearchTerms([...searchTerms.filter((v) => v !== input)])
  }
  const handleSort = (input) => {
    setOrderBy(input.target.value)
  }
  const handleKeyPress = (input) => {
    if (input.keyCode === 13) {
      setSearchTerms([...searchTerms, input.target.value])
      setSearchText('')
    }
  }

  const loadMoreOrders = useMemo(() => {
    return async () => {
      if (!loading) {
        setLoadingMore(true)
        const { orders, pagination: { total, nextOffset: newOffset } } = await queryWorkflowVerifications({
          searchTerms,
          orderBy,
          nextOffset,
        })
        setOrderSummaries([...orderSummaries, ...orders])
        setNextOffset(newOffset)
        setLoadingMore(false)
      }
    }

  }, [searchTerms, orderBy, nextOffset, orderSummaries, loading])

  useEffect(async () => {
    setLoading(true)
    const { orders, pagination: { total, nextOffset: newOffset } } = await queryWorkflowVerifications({
      searchTerms,
      orderBy,
      nextOffset: 0,
    })
    setOrderSummaries(orders)
    setNextOffset(newOffset)
    setLoading(false)
    setTotal(total)
  }, [searchTerms, orderBy])

  const handleCloseSidePanel = () => {
    setSelectedOrder(null)
  }
  const handleSelectOrder = (orderId) => {
    setSelectedOrder(orderId)
  }

  const tabs = useMemo(() => {
    return ORDER_TABS.map(({ tabLabel, tabValue, filterFunction }) => {
      const tabSummaries = orderSummaries.filter(filterFunction)
      return {
        tabLabel,
        tabValue,
        tabSummaries,
        // Do not get the tabCount from the filterFunction but instead from the total until we have faceting working correctly
        tabCount: total,
      }
    })
  }, [orderSummaries, total])

  return (
    <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id="search-input"
          fullWidth
          size="small"
          placeholder="Search"
          sx={{ backgroundColor: 'background.paper'}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          value={searchText}
          onChange={(evt) => setSearchText(evt.target.value)}
          onKeyDown={handleKeyPress}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
      <FormControl fullWidth size="small">
        <InputLabel id="search-sort-label">Sort</InputLabel>
        <Select
          labelId="search-sort-label"
          id="search-sort"
          value={orderBy}
          label="Sort"
          onChange={handleSort}
          sx={{ backgroundColor: 'background.paper'}}
        >
          <MenuItem value={'DATE_PLACED_DESC'}>By Order Date (descending)</MenuItem>
          <MenuItem value={'DATE_PLACED_ASC'}>By Order Date (ascending)</MenuItem>
          <MenuItem value={'TITLE_DESC'}>By Business Name (descending)</MenuItem>
          <MenuItem value={'TITLE_ASC'}>By Business Name (ascending)</MenuItem>
        </Select>
      </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} padding='1rem 1rem 1rem 0rem'>
          {searchTerms.map((value) => (
              <Chip key={`search-chip-${value}`} label={value} onDelete={() => handleDelete(value)} />
          ))}  
        </Stack>
        <VerificationTabs tabs={tabs} loading={loading} handleSelectOrder={handleSelectOrder} />
        <LoadMore loadMore={loadMoreOrders} nextOffset={nextOffset} isFetchingPage={loadingMore} />
        <Drawer
          anchor='right'
          open={!!selectedOrder}
          onClose={handleCloseSidePanel}
        >
          <VerificationSidePanel orderId={selectedOrder} />
        </Drawer>
      </Grid>
    </Grid>
    </Box>
  )
}

