import React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { API, graphqlOperation } from 'aws-amplify'
import { createApiKey, revokeApiKey } from '../../graphql/mutations'
import { subscribeToKey } from '../../graphql/subscriptions'
import { useState } from 'react'
import PrimaryPage from '../../Layout/PrimaryPage'
import { Typography, Button } from '@mui/material'

const Home = () => {
  const [hash, setHash] = useState("")
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const create = async () => {
    const res = await API.graphql(graphqlOperation(createApiKey, {
      input: {
        name: 'boom',
      },
    }))
    console.log('CREATE RES', res.data.createApiKey);
    setHash(res.data.createApiKey.key)


    const sub = API.graphql(graphqlOperation(subscribeToKey, {
      name: 'boom',
      // key: res.data.createApiKey.unhashedKey,
    })).subscribe({
      next: ({ provider, value}) => {
        console.log('SUB!!', provider, value)
      },
      error: (e) => {
        console.log('SUB ERROR!!', e)
      },
    })

    
  }

  const revoke = async () => {
    const res = await API.graphql(graphqlOperation(revokeApiKey, {
      input: {
        key: hash,
      },
    }))

    console.log('REVOKE RES', res);
  }

  return (
    <PrimaryPage
      headerTitle="Welcome to Mesh"
      headerWidget={
        <Typography>
          Welcome to the Mesh Hub. Here you can do a bunch of neat things. Lorem ipsum dolor sit amet, 
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore 
          et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
          tempor incididunt ut labore et dolore magna aliqua.
        </Typography>}
      bodyTitle="Get Ready to Verify!!!!">
        <Typography>
          Here is some content for the body
        </Typography>
        <Button variant='outlined' onClick={create}>And a button!</Button>
        <Button variant='outlined' onClick={revoke} color="error">Here's another</Button>
      </PrimaryPage>
  )
}

export default Home
