import { createContext } from 'react'

import {
  createApiKey,
  revokeApiKey,
} from '../graphql/mutations'

import {
  viewApiKeys,
} from '../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'

const ApiKeyContext = createContext()
export default ApiKeyContext

const ContextState = () => {
  
  const listKeys = async ({ status }) => {
    const options = graphqlOperation(
      viewApiKeys, {
        input: {
          limit: 100,
          status,
        },
      },
    )
    const result = await API.graphql(options)
    return result.data.viewApiKeys
  }

  const createKey = async ({ name }) => {
    const options = graphqlOperation(
      createApiKey, {
        input: {
          name,
        },
      },
    )

    const result = await API.graphql(options)
    return result.data.createApiKey
  }

  const revokeKey = async ({ apiKeyId }) => {
    const options = graphqlOperation(
      revokeApiKey, {
        input: {
          apiKeyId,
        },
      },
    )
    const result = await API.graphql(options)
    return result.data.revokeApiKey
  }

  return {
    listKeys,
    createKey,
    revokeKey,
  }
}

const ApiKeyContextProvider = (props) => {
  const state = ContextState()

  return (
    <ApiKeyContext.Provider value={state}>
      {props.children}
    </ApiKeyContext.Provider>
  ) 
}

export { ApiKeyContext, ApiKeyContextProvider }
