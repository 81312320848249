/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToKey = /* GraphQL */ `
  subscription SubscribeToKey($name: String!) {
    subscribeToKey(name: $name) {
      apiKeyId
      name
      status
      message
    }
  }
`;
export const subscribeToWorkflowVerification = /* GraphQL */ `
  subscription SubscribeToWorkflowVerification($workflowVerificationId: ID!) {
    subscribeToWorkflowVerification(
      workflowVerificationId: $workflowVerificationId
    ) {
      orderId
      vowId
      businessId
      userId
      items {
        vspecId
        vsku
        jurisdiction
        claims {
          name
          value
        }
        results {
          name
          value
        }
        labels
        itemStatus
        lastVerifiedTime
      }
      orderStatus
      labels
      title
      jurisdictions
      orderCreationTime
      lastUpdatedTime
      orderCompletionTime
    }
  }
`;
