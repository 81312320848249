import React, { useState } from 'react'
import { Button } from '@mui/material'
import CreateKeyModal from './CreateKeyModal'


const CreateKeyHeader = ({ setDisplayError, loadKeys }) => {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      {modalOpen && (
        <CreateKeyModal 
          setDisplayError={setDisplayError}
          loadKeys={loadKeys}  
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
      <Button sx={{
        marginBottom: '1rem',
        backgroundColor: 'primary.main',
        textTransform: 'none',
        color: 'primary.contrastText',
        ':hover': {
          backgroundColor: 'primary.light',
        },
      }} onClick={() => setModalOpen(true)}>
        Create API Key
      </Button>
    </>
  )
}

export default CreateKeyHeader
