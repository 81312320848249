import React from 'react'

import AddUserSection from './AddUserSection'
import UserListContainer from './UserListContainer'
import PrimaryPage from '../../Layout/PrimaryPage/index.jsx'

const Users = () => {
  return (
    <PrimaryPage
      headerTitle="User Management"
      infoTitle="Managing Users"
      infoDetail="Manage users within your organization here. Invite your co-workers to join you in using Mesh to perform verifications with you.">
        <AddUserSection />
        <UserListContainer />
    </PrimaryPage>
  )
}

export default Users
