import React from 'react'
import { useInView } from 'react-cool-inview'
import { Box, Button, LinearProgress } from '@mui/material'

export const LoadMore = ({ loadMore, nextOffset, isFetchingPage }) => {
  const { observe } = useInView({
    onEnter: () => {
      if (nextOffset) {
        loadMore()
      }
    },
  })

  return (
    <>
      <div ref={observe} />
      {isFetchingPage && <LinearProgress size={5} color={'primary'} sx={{ width: '100%' }} />}
      {(!isFetchingPage && !!nextOffset) && (
        <Box sx={{textAlign: 'center'}}><Button onClick={loadMore}>Load More</Button></Box>
      )}
    </>
  )
}

export default LoadMore
