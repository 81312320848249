import React, { useState } from 'react'
import { Grid, Box, Typography, Button, List, ListItem, Divider, CircularProgress } from '@mui/material'
import { BuildDropdown } from '../../Components/Inputs'

export const keyStatus = [
  {
    value: 'all',
    label: 'All Keys',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'revoked',
    label: 'Revoked',
  },
]

const KeyCard = ({ value, isAdmin, revokeKey }) => {
  const [revoking, setRevoking] = useState()
  const revoke = () => {
    setRevoking(value.apiKeyId)
    revokeKey({ apiKeyId: value.apiKeyId })
  }
  return (
    <Grid container elevation={2}>
      <Grid item xs={2} sx={{display: 'flex', alignItems: 'center'}}>
        <Typography paddingTop="0.58rem" paddingBottom="0.58rem">
          {value.name}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
        <Typography paddingTop="0.58rem" paddingBottom="0.58rem">
          {value.message}
        </Typography>
      </Grid>
      <Grid item xs={2} sx={{display: 'flex', alignItems: 'center'}}>
        <Typography paddingTop="0.58rem" paddingBottom="0.58rem">
          {value.status}
        </Typography>
        </Grid>
        <Grid item xs={2} sx={{display: 'flex', alignItems: 'center'}}>
          {revoking === value.apiKeyId && <CircularProgress />}
          {revoking !== value.apiKeyId && isAdmin && value.apiKeyId && value.status === 'active' && <Button variant='outlined' sx={{
            padding: '0.5rem 4rem',
            color: '#0071f4',
            backgroundColor: '#fff',
            border: '1px solid #0071f4',
            textTransform: 'none',
            borderRadius: '0.5rem',
          }} onClick={revoke}>
            Revoke
          </Button>}
      </Grid>
    </Grid>
  )
}

const KeySection = ({ loading, keys, isAdmin, status, setStatus, revokeKey }) => {
  return (
    <>  
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginBottom: '2rem',
      }}>
        <BuildDropdown currentValue={status} values={keyStatus} setter={(e) => {setStatus(e)}} isSmall disableUnderline={true}/>
      </Box>
      
      <TableHeaders/>
      <KeyList keys={keys} isAdmin={isAdmin} loading={loading} revokeKey={revokeKey} />
    </>
  )
}

const KeyList = ({ keys, isAdmin, loading, revokeKey }) => {
  if (loading && keys.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <CircularProgress />
      </Box>
    )
  }
  if (keys.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <Typography>No keys found</Typography>
      </Box>
    )
  }

  return (
    <List sx={{
      width: '100%',
      bgcolor: 'background.paper',
      }}>
      {keys.map((val, idx) => (
        <ListItem sx={{ paddingTop: '1', paddingBottom: '1' }} key={idx} divider={(idx + 1) < keys.length}>
          <KeyCard value={val} isAdmin={isAdmin} revokeKey={revokeKey} />
        </ListItem>
      ))}
    </List>
  )
}

const TableHeaders = () => {
  const headers = [
    {
      name: 'Name',
      flex: 2,
    },
    {
      name: 'Information',
      flex: 6,
    },
    { 
      name: 'Status',
      flex: 2,
    },
    { 
      name: '',
      flex: 2,
    },
  ]
  
  return (
    <Grid container>
      {headers.map((header, i) => (
        <Grid key={`header_${i}`} item xs={header.flex}>
          <Typography key={i} noWrap sx={{
            textAlign: header.textAlign || 'start',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontWeight: '600',
            fontSize: '1.5rem',
            color: '#58595b',
          }}>
            {header.name}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

export default KeySection
