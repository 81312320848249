import React, { useContext, useEffect, useState } from 'react'

import PrimaryPage from '../../Layout/PrimaryPage/index.jsx'

import CsvDialog from '../../Components/Dialogs'
import ApiKeyContext from '../../services/apiKeyService'
import WhoAmIContext from '../../services/whoami'

import CreateKeyHeader from './CreateKeyHeader'
import KeySection from './KeySection'



const ApiKeys = () => {
  const service = useContext(ApiKeyContext)

  const [loading, setLoading] = useState(true)
  const [keys, setKeys] = useState([])
  const [newKey, setNewKey] = useState()
  const [status, setStatus] = useState('active')
  const [displayError, setDisplayError] = useState(false)

  const { role } = useContext(WhoAmIContext)

  const revokeKey = async ({ apiKeyId }) => {
    try {
      const revoked = await service.revokeKey({ apiKeyId })
      loadKeys()
    }
    catch (err) {
      console.log('Error', err)
      setDisplayError(true)
    }
  }

  const loadKeys = () => {
    setLoading(true)
    service.listKeys({ status })
      .then((newKeys) => {
        setKeys(newKeys)
        setLoading(false)
      })
      .catch((err) => {
        console.log('Error', err)
        setDisplayError(true)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (loadKeys) {
      loadKeys()
    }
  }, [status])
  return (
    <>
      {displayError && (
        <CsvDialog 
          open={displayError}
          handleClose={() => setDisplayError(false)}
          type={'error'}
          message={'Something went wrong fetching records.'}
        />
      )}
      {newKey && (
        <CsvDialog 
          open={!!newKey}
          handleClose={() => setNewKey(undefined)}
          type={'info'}
          message={`Please note that this secret value will only be displayed once and cannot be viewed again: ${newKey}`}
        />
      )}
      <PrimaryPage
        headerTitle="Key Management"
        infoTitle="API Keys"
        infoDetail="Manage API Keys for your organization. API Keys can be used for programatic interactions with Mesh services. Please see the documentation at https://developer.mesh.id for more details about the API.">
          <CreateKeyHeader setDisplayError={setDisplayError} loadKeys={loadKeys} />
          <KeySection loading={loading} isAdmin={role === 'ADMIN'} status={status} setStatus={setStatus} keys={keys} revokeKey={revokeKey} />
      </PrimaryPage>
    </>
  )
}

export default ApiKeys
