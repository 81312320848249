import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Helmet } from 'react-helmet'

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return (
    <>
      <Helmet>
        {/* Start of meshsupport Zendesk Widget script */}
        <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=6db4da16-0abc-46d1-ad29-8d18a3a1cc86"> </script>
        {/* End of meshsupport Zendesk Widget script */}
      </Helmet>
      {children}
    </>
  )
}

export default RequireAuth
