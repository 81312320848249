import { createContext } from "react"

import {
  queryWorkflowVerifications as queryWorkflowVerificationsQuery,
  getWorkflowVerification as getWorkflowVerificationQuery,
} from '../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'

const WorkflowVerificationContext = createContext()
export default WorkflowVerificationContext

const ContextState = () => {
  const { user } = useAuthenticator((context) => [context.user])
  const authMode = {
    authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_LAMBDA",
  }

  const queryWorkflowVerifications = async ({ searchTerms, orderBy = 'DATE_PLACED_DESC', nextOffset = 0 }) => {
    const limit = 20
    const options = graphqlOperation(
      queryWorkflowVerificationsQuery, {
        search: {
          searchTerms,
          orderBy,
          pagination: {
            limit,
            offset: nextOffset ? nextOffset : 0,
          },
        },
      },
    )

    const result = await API.graphql({
      ...options,
      ...authMode,
    })

    return result.data.queryWorkflowVerifications
  }

  const getWorkflowVerification = async ({ orderId }) => {
    const options = graphqlOperation(
      getWorkflowVerificationQuery, {
        orderId,
      },
    )

    const result = await API.graphql({
      ...options,
      ...authMode,
    })

    return result.data.getWorkflowVerification
  }

  return {
    queryWorkflowVerifications,
    getWorkflowVerification,
  }
}

const WorkflowVerificationContextProvider = (props) => {
  const state = ContextState()

  return (
    <WorkflowVerificationContext.Provider value={state}>
      {props.children}
    </WorkflowVerificationContext.Provider>
  ) 
}

export { WorkflowVerificationContext, WorkflowVerificationContextProvider }
