import React from 'react'
import { TextField, InputAdornment } from '@mui/material'

const BuildTextField = ({ value, placholder, setter, endIcon }) => {
  const handleChange = (setter) => (event) => setter(event.target.value)

  return (
    <TextField 
      fullWidth
      size="small"
      variant='outlined'
      placeholder={placholder}
      value={value}
      sx={{
        width: 'max-content',
        backgroundColor: 'primary.contrastText',
        color: 'primary.main',
        textAlign: 'center',
      }}
      onChange={handleChange(setter)}
      autoComplete={'off'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {endIcon}
          </InputAdornment>
        ),
      }}
    />
  )
}

export default BuildTextField
