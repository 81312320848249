import React from 'react'
import {Box, Typography, CircularProgress } from '@mui/material'
import { Search } from '@mui/icons-material'
import UserCard from '../../Components/UserCard'
import { BuildDropdown, BuildTextField } from '../../Components/Inputs'

export const statusValues = [
  {
    value: 'ALL',
    label: 'All Statuses',
  },
  {
    value: 'ALL_ACTIVE',
    label: 'Active & Invited',
  },
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'INACTIVE',
    label: 'Inactive',
  },
  {
    value: 'INVITED',
    label: 'Invited',
  },
]

const userStatusValues = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'INACTIVE',
    label: 'Inactive',
  },
] 

export const roleValues = [
  {
    value: 'ALL',
    label: 'All Roles',
  },
  {
    value: 'MEMBER',
    label: 'User',
  },
  {
    value: 'ADMIN',
    label: 'Super User',
  },
]


const UsersSection = ({ users, filters, setFilters, isLoading }) => {
  const filterOnChange = async (key, e) => {
    if(key.includes('searchText'))
      setFilters((oldState) => ({
          ...oldState,
          searchText: e,
      }))
    if(key.includes('status'))
      setFilters((oldState) => ({
          ...oldState,
          status: e,
      }))
    if(key.includes('role'))
      setFilters((oldState) => ({
          ...oldState,
          role: e,
      }))
  }

  return (
    <>
      <FilterSection filter={filters} onFilterChange={filterOnChange} roleValues={roleValues} statusValues={statusValues} />
      <TableHeaders/>
      <UserList users={users} isLoading={isLoading} filterOnChange={filterOnChange} />
    </>
  )
}

const UserList = ({ users, isLoading, filterOnChange }) => {
  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <CircularProgress />
      </Box>
    )
  }
  if (users.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <Typography>No users found</Typography>
      </Box>
    )
  }

  return users.map((val, index) => (
    <UserCard key={index} batch={val} roleValues={roleValues.slice(1)} statusValues={userStatusValues} onFilterChange={filterOnChange} allowEditing={val.allowEditing} />
  ))
}

const FilterSection = ({ filter, onFilterChange, roleValues, statusValues }) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginBottom: '2rem',
    }}>
      <Box variant='contained' sx={{
        backgroundColor: 'primary.main',
        margin: '0 1rem 0 0',
        textTransform: 'none',
        color: 'primary.contrastText',
        display: 'flex',
        alignItems: 'center',
      }}>
        <BuildTextField
          value={filter.searchText}
          placholder={'type to search'}
          setter={(e) => {onFilterChange('searchText', e)}} disableUnderline={true}
          endIcon={<Search sx={{ margin: '0 0.5rem' }}/>} />
      
      </Box>

      <BuildDropdown currentValue={filter.role} isSmall values={roleValues} setter={(e) => {onFilterChange('role', e)}} disableUnderline={true}/>
      <BuildDropdown currentValue={filter.status} isSmall values={statusValues} setter={(e) => {onFilterChange('status', e)}} disableUnderline={true}/>
    </Box>
  )
}

const TableHeaders = () => {
  const headers = [
    {
      name: 'Name',
      textAlign: 'center',
      flex: '3',
    },
    {
      name: 'Role',
      flex: '2',
    },
    {
      name: 'Email',
      flex: '2',
    },
    { 
      name: 'Status',
      flex: '2',
    },
    { 
      name: '',
      flex: '1',
    },
  ]
  
  return (
    <Box sx={{
      width: '100%',
      margin: '0',
      padding: '1rem 0',
      textAlign: 'start',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}>
      {headers.map((header, i) => (
        <Typography key={i} variant='h4' color='emphasis.main' noWrap sx={{
          marginRight: '0.25rem',
          
          textAlign: header.textAlign || 'start',
          flex: header.flex || '1',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}>
          {header.name}
        </Typography>
      ))}
    </Box>
  )
}
export default UsersSection
