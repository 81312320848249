


import React from 'react'
import PrimaryPage from '../../Layout/PrimaryPage'
import { VerificationContainer } from './VerificationContainer'

const WorkflowVerification = () => {
  return (
    <PrimaryPage
      headerTitle="Orders"
      infoTitle="View the status of all your requested verification orders."
      infoDetail="Rapidly focus on businesses that aren't verified, or search and filter for any business.">
        <VerificationContainer />
    </PrimaryPage>
  )
}

export default WorkflowVerification
