import React from 'react'
import { Outlet } from 'react-router'
import { Box } from '@mui/material'
import Drawer from '../../Components/Drawer/Drawer'

const Sidebar = () => {
  return (
    <Box sx={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'background.default',
      overflow: 'hidden',
    }}>

      <Box sx={{
        boxSizing:  'border-box',
        width: '16rem',
        height: '100%',
      }}>
        <Drawer/>
      </Box>

      <Box sx={{
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem 0',
        overflowY: 'auto',
      }}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default Sidebar
