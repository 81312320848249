import React, { useState, useContext } from 'react'
import { Box, Button, Typography, CircularProgress } from '@mui/material'
import PrimaryPage from '../../Layout/PrimaryPage'
import { BuildDropdown, BuildTextField } from '../../Components/Inputs'
import CsvDialog from '../../Components/Dialogs'
import UserContext from '../../services/userService'
import { useNavigate } from 'react-router-dom';

const InviteUser = () => {
  return (
    <PrimaryPage
      headerTitle="Add a new user"
      headerWidget={(
        <DescriptionSection/>
      )}
      bodyTitle="Who are you inviting?">
        <InviteUserSection/>
    </PrimaryPage>
  )
}

const DescriptionSection = () => {
  return (
    <Typography alignItems='left' sx={{
      fontSize: '1rem',
      wordWrap: 'break-word',
      marginBottom: '1.5rem',
    }}>
      Invite a user to take part in verifying professional licenses utilizing Mesh.
    </Typography>
  )
}

const InviteUserSection = () => {
  const service = useContext(UserContext)
  const navigate = useNavigate()
  const [isInviting, setIsInviting] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const[filters, setFilters] = useState({
    email: '',
    role: '',
  })

  const roleValues = [
    {
      value: '',
      label: 'Select roles',
    },
    {
      value: 'MEMBER',
      label: 'User',
    },
    {
      value: 'ADMIN',
      label: 'Super User',
    },
  ]

  const filterOnChange = async (key, e) => {
    if(key.includes('email'))
      setFilters((oldState) => ({
          ...oldState,
          email: e,
      }))
    if(key.includes('role'))
      setFilters((oldState) => ({
          ...oldState,
          role: e,
      }))
  }

  const inviteUser = async () => {
    if(filters.email !== '' && filters.role !== '') {
      setIsInviting(true)
      console.log('inviting')
      const response = await service.invite(filters)
      setIsInviting(false)
      setIsOpen(true)
    } else {
      setIsError(true)
      setIsOpen(true)
    }
  }

  const cancel = () => {
    navigate(-1)
  }

  return (
    <>
      {isOpen && isError === false && <CsvDialog 
        open={isOpen}
        handleClose={() => {
          navigate('/settings/users')
        }}
        type={'user'}
      />}
      {isOpen && isError && <CsvDialog 
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        type={'error'}
        message={'Must fill out fields to add a user.'}
      />}

      <FilterSection filter={filters} onFilterChange={filterOnChange} roleValues={roleValues}/>
        <Box>
        <Button variant='outlined'
          color="error"
          sx={{ marginRight: '1rem' }}
          onClick={cancel}
          disabled={isInviting}>
        Cancel
      </Button>
      <Button
        variant='contained'
        onClick={inviteUser}
        disabled={isInviting}>
        {isInviting ? <CircularProgress sx={{ color: '#0071f4'}} size={35} color={'inherit'}/>  : 'Send invitation'}
      </Button>
        </Box>
      
    </>
  )
}

const FilterSection = ({ filter, onFilterChange, roleValues }) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginBottom: '2rem',
    }}>
      <Box variant='contained'
        sx={{ marginRight: '1rem' }}>
        <BuildTextField 
          value={filter.email} 
          placholder={'Enter e-mail'} 
          setter={(e) => {onFilterChange('email', e)}} 
          disableUnderline={true}
        />
      </Box>

      <BuildDropdown 
        currentValue={filter.role}
        values={roleValues}
        isSmall
        setter={(e) => {onFilterChange('role', e)}}
        disableUnderline={true}
      />
    </Box>
  )
}
export default InviteUser
