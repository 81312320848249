import React, { useContext } from 'react'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import WhoAmIContext from '../../services/whoami'


const AddUserSection = () => {
  const navigate = useNavigate()

  const whoami = useContext(WhoAmIContext)
  const goToPage = () => navigate('/settings/users/invite')
  if (!whoami.loaded) {
    return null
  }
  if (whoami.role !== 'ADMIN') {
    return null
  }
  return (
    <>
      <Button sx={{
        marginBottom: '1rem',
        backgroundColor: 'primary.main',
        color: 'primary.contrastText',
        ':hover': {
          backgroundColor: 'primary.light',
        },
      }} onClick={() => goToPage()}>
        Add a user
      </Button>
    </>

  )
}

export default AddUserSection
