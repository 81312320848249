/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const inviteUser = /* GraphQL */ `
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      userId
      customerId
      customer {
        customerId
        status
        customerName
        customerUrl
        customerLogo
        entitlements
        vows {
          vowId
          vowConfigId
          description
          items {
            vskuId
            vsku {
              vskuId
              type
              inputClaims
              outputResults
            }
            vspecId
            isRequired
            isRepeatable
          }
          redirectSettings {
            redirectUri
            includeUser
          }
        }
      }
      emailAddress
      status
      role
      allowEditing
      fullName
      firstName
      lastName
    }
  }
`;
export const reinviteUser = /* GraphQL */ `
  mutation ReinviteUser($input: ReinviteUserInput!) {
    reinviteUser(input: $input) {
      userId
      customerId
      customer {
        customerId
        status
        customerName
        customerUrl
        customerLogo
        entitlements
        vows {
          vowId
          vowConfigId
          description
          items {
            vskuId
            vsku {
              vskuId
              type
              inputClaims
              outputResults
            }
            vspecId
            isRequired
            isRepeatable
          }
          redirectSettings {
            redirectUri
            includeUser
          }
        }
      }
      emailAddress
      status
      role
      allowEditing
      fullName
      firstName
      lastName
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UserUpdateInput!) {
    updateUser(input: $input) {
      userId
      customerId
      customer {
        customerId
        status
        customerName
        customerUrl
        customerLogo
        entitlements
        vows {
          vowId
          vowConfigId
          description
          items {
            vskuId
            vsku {
              vskuId
              type
              inputClaims
              outputResults
            }
            vspecId
            isRequired
            isRepeatable
          }
          redirectSettings {
            redirectUri
            includeUser
          }
        }
      }
      emailAddress
      status
      role
      allowEditing
      fullName
      firstName
      lastName
    }
  }
`;
export const createApiKey = /* GraphQL */ `
  mutation CreateApiKey($input: NamedKeyInput!) {
    createApiKey(input: $input) {
      name
      key
      apiKeyId
    }
  }
`;
export const revokeApiKey = /* GraphQL */ `
  mutation RevokeApiKey($input: KeyInput!) {
    revokeApiKey(input: $input) {
      apiKeyId
      name
      status
      message
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($userId: ID, $input: NotificationInput!) {
    updateNotification(userId: $userId, input: $input) {
      userId
      timezone
      notifications {
        notificationChannel
        notificationTypes
        phoneNumber
        emailAddress
        hookUrl
        channel
        secret
      }
    }
  }
`;
export const submitWorkflowVerification = /* GraphQL */ `
  mutation SubmitWorkflowVerification($input: WorkflowVerificationInput) {
    submitWorkflowVerification(input: $input) {
      successful
      errorMessages
      order {
        orderId
        vowId
        businessId
        userId
        items {
          vspecId
          vsku
          jurisdiction
          claims {
            name
            value
          }
          results {
            name
            value
          }
          labels
          itemStatus
          lastVerifiedTime
        }
        orderStatus
        labels
        title
        jurisdictions
        orderCreationTime
        lastUpdatedTime
        orderCompletionTime
      }
    }
  }
`;
export const workflowVerificationResult = /* GraphQL */ `
  mutation WorkflowVerificationResult($input: WorkflowVerificationResult) {
    workflowVerificationResult(input: $input) {
      orderId
      vowId
      businessId
      userId
      items {
        vspecId
        vsku
        jurisdiction
        claims {
          name
          value
        }
        results {
          name
          value
        }
        labels
        itemStatus
        lastVerifiedTime
      }
      orderStatus
      labels
      title
      jurisdictions
      orderCreationTime
      lastUpdatedTime
      orderCompletionTime
    }
  }
`;
export const createVowConfig = /* GraphQL */ `
  mutation CreateVowConfig($input: VowConfigInput!) {
    createVowConfig(input: $input)
  }
`;
export const createVowAlias = /* GraphQL */ `
  mutation CreateVowAlias($input: VowAliasCreateInput!) {
    createVowAlias(input: $input)
  }
`;
export const updateStatusVowAlias = /* GraphQL */ `
  mutation UpdateStatusVowAlias($input: VowAliasUpdateStatusInput!) {
    updateStatusVowAlias(input: $input)
  }
`;
export const deleteVowAlias = /* GraphQL */ `
  mutation DeleteVowAlias($input: VowAliasDeleteInput!) {
    deleteVowAlias(input: $input)
  }
`;
export const requestBusiness = /* GraphQL */ `
  mutation RequestBusiness($input: RequestBusinessInput!) {
    requestBusiness(input: $input) {
      businessId
      aliases {
        namespace
        identifier
      }
    }
  }
`;
