import React from 'react'

const NotFound = () => {
  return (
    <div className='home'>
      <h2>Oops! You seem to have stumbled upon a broken page. Please head <a href="/">here to try again.</a></h2>
    </div>
  )
}

export default NotFound
