import React from 'react'
import { TextField, MenuItem } from '@mui/material'

const BuildDropdown = ({ currentValue, values, setter, disableUnderline, isSmall }) => {
  const handleChange = (setter) => (event) => setter(event.target.value)

  return (
    <TextField 
      id="outlined-select-basic"
      select
      variant="outlined"
      size={isSmall ? 'small' : 'default'}
      value={values.filter((f) => f.value === currentValue)[0]?.label}
      sx={{
        width: 'min-content',
        marginRight: '1rem',
        backgroundColor: 'background.paper',
      }}
      InputProps={{style: {
        textAlign: 'start',
        fontSize: '1rem',
        color: 'primary.contrastText',
        marginRight: '5rem',
      }, disableUnderline }}
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
      onChange={(e) => handleChange(setter)({ target: {value: values.filter((f) => f.label === e.target.value)[0].value}})}
    >
      {values.map((option, index) => (
        <MenuItem key={index} value={option.label} sx={{ textAlign: 'start' }}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default BuildDropdown
