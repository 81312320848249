import React, { useState, useMemo, useContext, useEffect } from 'react'
import UserContext from '../../services/userService'
import CsvDialog from '../../Components/Dialogs'
import UsersSection from './UsersSection'

const rollupStatusFilters = {
  ALL_ACTIVE: ['ACTIVE', 'INVITED'],
  ALL: ['ACTIVE', 'INACTIVE', 'INVITED'],
}

const UserListContainer = () => {
  const service = useContext(UserContext)
  const [users, setUsers] = useState([])
  
  const [isLoading, setIsLoading] = useState(true)

  const[filters, setFilters] = useState({
    searchText: '',
    status: 'ALL_ACTIVE',
    role: 'ALL',
  })

  const [displayError, setDisplayError] = useState(false)

  const getUsers = async () => {
    try {
      const newUsers = await service.listAllUsers()
      setUsers(newUsers)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setDisplayError(true)
    }
  }

  const filteredUsers = useMemo(() => {
    return users
      .filter(({ emailAddress, fullName, role, status }) => {
        return (rollupStatusFilters[filters.status]
          ? rollupStatusFilters[filters.status].includes(status)
          : filters.status === status)
          && (filters.role === 'ALL' || filters.role === role)
          && (
            filters.searchText === '' ||
            emailAddress.toLowerCase().includes(filters.searchText.toLowerCase()) || 
            fullName.toLowerCase().includes(filters.searchText.toLowerCase()))
      })
      .sort(({ fullName: a, emailAddress: aEmail }, { fullName: b, emailAddress: bEmail }) => {
        if (a === b) {
          return aEmail.localeCompare(bEmail)
        }
        if (a === 'undefined undefined') {
          return 1
        }
        if (b === 'undefined undefined') {
          return -1
        }
        return a.localeCompare(b)
      })
  }, [users, filters])

  useEffect(() => {
    let timeout
    timeout = setInterval(() => {
      getUsers()
    }, 3000)
    return () => {
      if (timeout) {
        clearInterval(timeout)
      }
    }
  }, [])

  return (
    <>
      {displayError && (
        <CsvDialog 
          open={displayError}
          handleClose={() => setDisplayError(false)}
          type={'error'}
          message={'Something went wrong fetching records.'}
        />
      )}
      <UsersSection users={filteredUsers} filters={filters} setFilters={setFilters} isLoading={isLoading} />
    </>
    
  )
}



export default UserListContainer
