import React, { useState, useContext } from 'react'
import { Modal, Box, Button, TextField, Typography, CircularProgress } from '@mui/material'
import { ReactComponent as Green } from '../../Assets/green_csv.svg'
import { ApiKeyContext } from '../../services/apiKeyService'

const CreateKeyContents = ({ loading, newKey, name, setName, createKey, setModalOpen }) => {
  if (loading) {
    return (
      <>
        <Green width={'8rem'}/>
        <CircularProgress />
      </>
    )
  }

  if (newKey) {
    return (
      <>
        <Green width={'8rem'}/>
        <Typography sx={{
          fontWeight: '400',
          textAlign: 'center',
          fontSize: '1.1rem',
          color: '#424242',
          margin: '1rem 0 2rem 0',
        }}>
          API Key has been created. Please note that the secret will only be available while this modal is
          displayed and cannot be retrieved again after it is closed.
        </Typography>
        <Typography sx={{
          fontWeight: '400',
          textAlign: 'center',
          fontSize: '1.3rem',
          margin: '1rem 0 2rem 0',
        }}>
          {newKey}
        </Typography>
        <Button variant='outlined' sx={{
          padding: '0.5rem 5rem',
          color: '#0071f4',
          backgroundColor: '#fff',
          border: '1px solid #0071f4',
          textTransform: 'none',
          borderRadius: '0.5rem',
          fontSize: '1.25rem',
        }} onClick={() => setModalOpen(false)}>
          Dismiss
        </Button>
      </>
    )
  }

  return (
    <>
      <Green width={'8rem'}/>
      <Typography sx={{
        fontWeight: '400',
        textAlign: 'center',
        fontSize: '1.1rem',
        color: '#424242',
        margin: '1rem 0 2rem 0',
      }}>
        API Keys are given a unique name for audit purposes. Please provide the desired name for this API Key
      </Typography>
      <TextField 
        variant="outlined"
        placeholder="Name"
        value={name}
        sx={{
          marginBottom: '1rem',
        }}
        InputProps={{
          style: {
            textAlign: 'center',
          },
          disableUnderline: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        onChange={(event) => setName(event.target.value)}
      />
      <Button sx={{
          backgroundColor: 'primary.main',
          textTransform: 'none',
          color: 'primary.contrastText',
          ':hover': {
            backgroundColor: 'primary.light',
          },
        }} onClick={createKey}>
          Create API Key
      </Button>
    </>
  )
}

const CreateKeyModal = ({ setDisplayError, loadKeys, modalOpen, setModalOpen }) => {
  const service = useContext(ApiKeyContext)

  const [loading, setLoading] = useState(false)
  const [newKey, setNewKey] = useState()
  const [name, setName] = useState('')

  const createKey = async () => {
    try {
      setLoading(true)
      const keyDetails = await service.createKey({ name })
      console.log('keyDetails', keyDetails)
      setNewKey(keyDetails.key)
      setLoading(false)
      loadKeys()
    }
    catch (err) {
      setLoading(false)
      console.log('Error', err)
      setDisplayError(true)
      setModalOpen(false)
    }
  }


  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        borderRadius: '0.5rem',
      }}>
        <CreateKeyContents loading={loading} newKey={newKey} setName={setName} name={name} createKey={createKey} setModalOpen={setModalOpen} />
      </Box>
    </Modal>
  )
}


export default CreateKeyModal
