import React, { useContext, useState } from 'react'
import { Paper, Box, Grid, Typography, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material';
import WhoAmIContext from '../../services/whoami'

const PrimaryPage = ({ headerTitle, infoTitle, infoDetail, children }) => {
  const { loaded, businessName } = useContext(WhoAmIContext)
  const [showInfo, setShowInfo] = useState(true)
  return (
    <Box padding='1.5rem 2.5rem 0rem 2.5rem' sx={{ backgroundColor: 'background.main', minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="h1" color="emphasis.dark">{headerTitle}</Typography>
        {infoTitle && showInfo && (
          <Paper sx={{ padding: '1.5rem', marginTop: '1.75rem', backgroundColor: 'primary.light' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant='h4' color='primary.contrastText'>{infoTitle}</Typography>
              <IconButton aria-label="close"onClick={() => setShowInfo(false)}>
                <Close sx={{ color: 'primary.contrastText' }} />
              </IconButton>
            </Box>
            {infoDetail && (
              <Typography color='primary.contrastText'>{infoDetail}</Typography>
            )}
          </Paper>
        )}
        <Box marginTop='3rem'>
          {children}
        </Box>
      </Box>
      {loaded && (
        <Box
          paddingTop='1rem'
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ opacity: 0.5 }}>
          We ❤️ {businessName}
        </Box>
      )}
    </Box>
  )
}



export default PrimaryPage

