import React, { useState } from 'react';
import { Box, Grid, Chip, Stack, Tabs, Tab, CircularProgress } from '@mui/material';
import { VerificationList } from './VerificationList';

export const VerificationTabs = ({ tabs, loading, handleSelectOrder }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const handleTab = (input, newTab) => {
    setCurrentTab(newTab)
  }
  if (!Array.isArray(tabs) || loading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <>
      <Grid xs={12} item sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleTab} aria-label="verification order tabs">
          {tabs.map(({ tabLabel, tabCount, tabValue }, index) => 
            <Tab key={`tab-label-${tabValue}`}label={<TabLabel label={tabLabel} count={tabCount} isActive={currentTab === index} />} {...a11yProps(index)} />,
          )}
        </Tabs>
      </Grid>
      <Grid xs={12} item >
        {tabs.map(({ tabValue, tabSummaries }, index) => 
          <TabPanel key={`tab-value-${tabValue}`} value={currentTab} index={index}>
            <VerificationList orderSummaries={tabSummaries} handleSelectOrder={handleSelectOrder} />
          </TabPanel>,
        )}
      </Grid>
    </>
  )
}

const TabLabel = ({ label, count, isActive }) =>
  <Stack direction="row" spacing='0.5rem' alignItems='center'>
    <span>{label}</span>
    <Chip color={isActive ? 'primary' : 'default'} label={count} />
  </Stack>

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}
